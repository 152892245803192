module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon/favicon-big.png","icons":[{"src":"favicons/favicon.ico","type":"image/x-icon","sizes":"48x48"},{"src":"favicons/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"favicons/favicon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fe8adda260d5fd0f187f6f4604be3d95"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://wpcms.schloss-teutschenthal.de/gatsby-endpoint","verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
