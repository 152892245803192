exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hochzeiten-js": () => import("./../../../src/pages/hochzeiten.js" /* webpackChunkName: "component---src-pages-hochzeiten-js" */),
  "component---src-pages-hotel-js": () => import("./../../../src/pages/hotel.js" /* webpackChunkName: "component---src-pages-hotel-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-karriere-js": () => import("./../../../src/pages/karriere.js" /* webpackChunkName: "component---src-pages-karriere-js" */),
  "component---src-pages-schloss-js": () => import("./../../../src/pages/schloss.js" /* webpackChunkName: "component---src-pages-schloss-js" */),
  "component---src-pages-weihnachtsfeiern-js": () => import("./../../../src/pages/weihnachtsfeiern.js" /* webpackChunkName: "component---src-pages-weihnachtsfeiern-js" */)
}

